
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class multiSelect extends Vue {
  @Prop() private label!: string
  @Prop() private description!: string
  @Prop() private icon!: string
  @Prop() private img!: string
  @Prop() private route!: any

  hover = false

  toRoute() {
    console.log(this.route)
    if (this.route && this.route.substring(0, 4) === 'http') {
      window.open(this.route)?.focus()
    } else {
      this.$router.push(JSON.parse(this.route))
    }
  }
}
